import React from "react";
import "./heading.scss";

function Heading({ children }) {
  return (
    <div className="text-6xl font-bold text-center text-white p-4 text-shadow">
      {children}
    </div>
  );
}

export default Heading;
