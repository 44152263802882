import { graphql } from "gatsby";
import * as React from "react";
import Seo from "../../components/seo";
import Heading from "../../components/heading/heading";
import Navbar from "../../components/navbar";

export const query = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;

const BlogIndex = ({ data }) => {
  const siteMetadata = data.site.siteMetadata;
  const blogs = data.allMarkdownRemark.edges;
  return (
    <>
      <Seo
        title="Blogs"
        description={`Blogs curated by ${siteMetadata?.social?.twitter || "author"}`}
      />
      <Navbar/>
      <Heading>Blogs</Heading>
      <div>
        {blogs.map((blog, index) => (
          <div key={blog.node.id}>
            <h2>
              <a href={`/blog/${blog.node.fields.slug}`}>
                {blog.node.frontmatter.title}
              </a>
            </h2>
            <p>{blog.node.frontmatter.date}</p>
            <p>{blog.node.excerpt}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default BlogIndex;